import React, { useState, useCallback, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { platformDetails } from "../../graphql/queries/systemQueries";
import {
  updatePlatformMutation,
  heightenImageMutation,
  widenImageMutation,
} from "../../graphql/mutations/systemMutations";
import Permissions, {
  landlordNoAccessFallbackList,
} from "../../configs/permissions";
import usePermissions from "../../hooks/permissions";
import {
  ErrorNotification,
  SuccessNotification,
} from "../../mood-ui/Notifications";
import useBaseFieldSetters from "./../../hooks/baseFieldSetters";
import useHyperState from "./../../hooks/hyperState";
import useFormErrors from "../../hooks/formErrors";
import useNotification from "../../hooks/notifications";
import useBaseNavigation from "../../hooks/baseNavigation";
import useModal from "./../../hooks/modal";
import useDataProvider from "./../../hooks/dataProvider";
import { OFFER_MODEL_NAME, SECTION_BEHAVIORS } from "../../configs/constants";
import {
  PageContainer,
  BlockContainer,
  Row,
  Col,
  FormContainer,
  FormData,
} from "../../mood-ui/Layout";
import ImageGalleryModal from "./../../mood-ui/ImageGalleryModal";
import { FormControls, SaveButton } from "../../mood-ui/Controls";
import {
  TextField,
  SelectField,
  ToggleCheckboxField,
  RichTextEditorField,
} from "../../mood-ui/Fields";
import { ColorPicker } from "../../mood-ui/ColorPicker";
import { Heading } from "../../mood-ui/Typography";
import LabelContextSwitch from "../../mood-ui/LabelContextSwitcher";
import { LabelContext } from "../../contexts/LabelContext";
import ClientOverview from "./../../components/reports/ClientOverview";

const PLATFORM_DETAILS = gql(platformDetails);
const UPDATE_PLATFORM = gql(updatePlatformMutation);
const HEIGHTEN_IMAGE = gql(heightenImageMutation);
const WIDEN_IMAGE = gql(widenImageMutation);

const LandlordPlatform = ({ entityDetails = null }) => {
  const { id: ownerId } = useParams();

  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Query.ClientPlatform, {
    noAccessFallbackList: landlordNoAccessFallbackList,
    variables: { id: ownerId },
  });

  const { composeQueryString } = useBaseNavigation(OFFER_MODEL_NAME);

  const labelContext = useContext(LabelContext);

  const [
    offers,
    // eslint-disable-next-line
    _,
    onRefetchOffers,
  ] = useDataProvider("offers", {
    filters: {
      landlord_id: [ownerId],
      activity: ["onlyTrulyActive"],
      label_id: [labelContext.activeLabel],
    },
  });

  useEffect(() => {
    onRefetchOffers({
      queryString: composeQueryString({
        landlord_id: [ownerId],
        activity: ["onlyTrulyActive"],
        label_id: [labelContext.activeLabel],
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labelContext.activeLabel]);

  const { modalState, showModal, closeModal } = useModal();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
    syncView,
  } = useHyperState("formData", {
    ownerId,
    labelId: "",
    platform_name: "",
    subdomain: "",
    featured_offer: 0,
    barrier_survey_enabled: false,
    frequency_survey_enabled: false,
    section_behavior: "GLOBAL",
    section_ids: "",
    primary_colour: "",
    secondary_colour: "",
    button_colour: "",
    button_text_colour: "",
    bg_color: "",
    logo_src: "",
    login_bg_src: "",
    promo_page_title: "",
    promo_page_description: "",
    promo_page_contact_email: "",
    footer_message: "",
    show_activation_status: false,
    show_mortgage_answers: false,
    show_rating_answers: false,
    show_access_type: true,
    allow_csv_export: false,
  });

  const {
    formHasErrors,
    setFieldError,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();
  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const { loading, error } = useQuery(PLATFORM_DETAILS, {
    variables: {
      ownerId,
      labelId: labelContext.activeLabel,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.platform) return false;
      setFormData({ ...result.platform, ownerId });
    },
  });

  const [
    update,
    {
      // data: updateResult,
      loading: isUpdating,
      // error: updateError
    },
  ] = useMutation(UPDATE_PLATFORM, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Update OK",
        message: "Successfully updated client platform",
        secondsToDisplay: 1,
      });
    },
    onError: (e) => {
      displayErrorNotification({
        heading: "Error",
        message: "Failed to update",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const [heightenLogoImage] = useMutation(HEIGHTEN_IMAGE, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Processing Logo",
        message: "Logo image was successfully resized",
        secondsToDisplay: 1,
      });
      handleFieldOnChange({
        target: {
          name: "logo_src",
          value: result.heightenImage.public_url,
        },
      });
    },
    onError: (e) => {
      console.log("e");
      displayErrorNotification({
        heading: "Logo Processing Error",
        message: "Failed to resize logo image",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const [widenBackgroundImage] = useMutation(WIDEN_IMAGE, {
    onCompleted: (result) => {
      displayNotification({
        heading: "Processing Background Image",
        message: "Background image was successfully resized",
        secondsToDisplay: 1,
      });
      handleFieldOnChange({
        target: {
          name: "login_bg_src",
          value: result.widenImage.public_url,
        },
      });
    },
    onError: (e) => {
      console.log(e);
      displayErrorNotification({
        heading: "Processing Background Image",
        message: "Failed to resize background image",
        secondsToDisplay: 2,
      });
      resolveMutationErrors(e);
    },
  });

  const handleLogoSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        displayNotification({
          heading: "Processing Logo",
          message: "Resizing selected logo image",
          secondsToDisplay: 2,
        });
        heightenLogoImage({ variables: { file_id: file.id, height: 200 } });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoginBGSelectModal = useCallback(() => {
    showModal({
      onConfirm: (file) => {
        widenBackgroundImage({
          variables: { file_id: file.id, width: 1240 },
        });
        closeModal();
      },
      onCancel: () => {
        closeModal();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = () => {
    const data = { ...formData, labelId: labelContext.activeLabel };
    update({ variables: data });
    setDraft((d) => false);
  };

  const [isDraft, setDraft] = useState(false);

  if (error) return <div>Error...</div>;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Platform settings"
              description="Settings related to the branding and functionality of the platform
                          for users and clients."
            />
            <ClientOverview
              entityDetails={entityDetails}
              groupFilter={["users", "growth"]}
              dense
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Platform details"
                      description="The account name is how we reference the client to their users and the 
                                  subdomain can be changed but only if absolutely necessary as links in
                                  all previous emails will no longer work."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-3">
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="platform_name"
                      label="Platform name"
                      value={formData?.platform_name}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("platform_name")}
                      isRequired={true}
                      maxlength="42"
                      description="Used throughout email comms"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="subdomain"
                      label="Subdomain"
                      value={formData?.subdomain}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("subdomain")}
                      isRequired={true}
                      maxlength="48"
                      pattern="[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?"
                      description="Should never be changed"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="footer_message"
                      label="Custom Message"
                      value={formData?.footer_message || ""}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("footer_message")}
                      isRequired={true}
                      minlength="5"
                      maxlength="64"
                      description="Shown on the login and activation pages"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Branded 'learn' page"
                      description="This page uses the below platform branding and displays 
                                  an always-up-to-date list of offers unique to each client. "
                    />
                    <p className="text-xs text-[#592373] opacity-60 underline underline-offset-4 mt-2 select-none">
                      <a
                        href={`https://${formData?.subdomain}.vaboo.co.uk/learn`}
                        rel="noreferrer"
                        target="_BLANK"
                      >
                        {formData?.subdomain}.vaboo.co.uk/learn
                      </a>
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-2">
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="promo_page_title"
                      label="Page title"
                      value={formData?.promo_page_title}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("promo_page_title")}
                      isRequired={true}
                      minlength="5"
                      maxlength="150"
                      description="The page heading"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <TextField
                      loading={loading}
                      name="promo_page_contact_email"
                      label="Support email address"
                      value={formData?.promo_page_contact_email}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("promo_page_contact_email")}
                      isRequired={true}
                      mode="email"
                      minlength="5"
                      maxlength="48"
                      description="For enquiries about access"
                    />
                  </Col>
                  <Col width={12} extraStyles="">
                    <RichTextEditorField
                      loading={loading}
                      name="promo_page_description"
                      label="Page message"
                      value={formData?.promo_page_description || ""}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      error={fieldError("promo_page_description")}
                      rows={4}
                      isRequired={true}
                      description="Must make sense to all user types"
                      minlength="5"
                      maxlength="1000"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Client dashboard content"
                      description="Control the functionality of the user management
                                   feature in a client's admin account."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-2">
                  <Col width={4} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="show_activation_status"
                      name="show_activation_status"
                      label="Activation"
                      isChecked={formData?.show_activation_status}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("show_activation_status")}
                      description="Show active/inactive for users"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="show_access_type"
                      name="show_access_type"
                      label="Access types"
                      isChecked={formData?.show_access_type}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("show_access_type")}
                      description="Show premium/basic for users"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="allow_csv_export"
                      name="allow_csv_export"
                      label="CSV export"
                      isChecked={formData?.allow_csv_export}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("allow_csv_export")}
                      description="Can export list of users"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="show_mortgage_answers"
                      name="show_mortgage_answers"
                      label="Buyer/seller"
                      isChecked={formData?.show_mortgage_answers}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("show_mortgage_answers")}
                      description="View and filter buyer and seller intent"
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="show_rating_answers"
                      name="show_rating_answers"
                      label="NPS data"
                      isChecked={formData?.show_rating_answers}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("show_rating_answers")}
                      description="View and filter ratings and comments"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Featured content"
                      description="Replace or extend the default featured content in this platform."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-y-4">
                  <Col width={4} extraStyles="">
                    <SelectField
                      loading={loading}
                      value={formData?.featured_offer}
                      values={offers || []}
                      label="Featured Offer"
                      name="featured_offer"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                      }}
                      valueKey="id"
                      labelKey="admin_title"
                      description="Offer at the top of the platform"
                      transformText
                      error={fieldError("featured_offer")}
                      prependOption="Use Global Settings"
                      prependOptionValue={0}
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    <SelectField
                      loading={loading}
                      value={formData?.section_behavior}
                      values={SECTION_BEHAVIORS || []}
                      label="Section Behavior"
                      name="section_behavior"
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                        // isFormValid()
                      }}
                      valueKey="id"
                      labelKey="name"
                      error={fieldError("section_behavior")}
                      description="Scrolling offers on the platform"
                    />
                  </Col>
                  <Col width={4} extraStyles="">
                    {formData?.section_behavior !== "GLOBAL" && (
                      <TextField
                        loading={loading}
                        name="section_ids"
                        label="Section IDs"
                        value={formData?.section_ids}
                        onChange={(e) => {
                          handleFieldOnChange(e);
                        }}
                        error={fieldError("section_ids")}
                        isRequired={true}
                        description="Comma-separated list of ids"
                      />
                    )}
                  </Col>
                  <Col extraStyles="w-fit">
                    <LabelContextSwitch dense icon={false} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Survey Configurations"
                      description="Turn on/off barrier and frequency Surveys."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-x-2">
                  <Col width={6} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="barrier_survey_enabled"
                      name="barrier_survey_enabled"
                      label="Barrier survey"
                      description="Enables barrier surveys"
                      isChecked={formData?.barrier_survey_enabled}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("barrier_survey_enabled")}
                    />
                  </Col>
                  <Col width={6} extraStyles="">
                    <ToggleCheckboxField
                      loading={loading}
                      id="frequency_survey_enabled"
                      name="frequency_survey_enabled"
                      label="Frequency survey"
                      description="Enables NPS surveys"
                      isChecked={formData?.frequency_survey_enabled}
                      onChange={(e) => {
                        handleToggleCheckbox(e);
                        setDraft(true);
                      }}
                      error={fieldError("frequency_survey_enabled")}
                    />
                  </Col>
                  <Col extraStyles="w-fit">
                    <LabelContextSwitch dense icon={false} />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Platform branding"
                      description="Hover on a colour or click an image to make changes. 
                                  The logo will be resized to a height of 200px and
                                  the background image to a width of 1240px. Ideally use
                                  a .png for the logo and a .jpeg for the background. 
                                  Make sure the header secondary text is visible and the
                                  background colour is dark. If the logo has a background colour, 
                                  make sure it matches the primary colour."
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row tweaks="gap-x-2ss">
                  <Col width={4}>
                    {/* <TextField
                      name="logo_src"
                      label="Platform logo"
                      value={formData.logo_src}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        if (e.target.value.length == 0) syncView(); 
                        setDraft(d => true);
                      }}
                      error={fieldError("logo_src")}
                    />
                    <div className="w-full py-1.5"></div>
                    <TextField
                      name="login_bg_src"
                      label="Login background"
                      value={formData.login_bg_src}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        if (e.target.value.length == 0) syncView(); 
                        setDraft(d => true);
                      }}
                      error={fieldError("login_bg_src")}
                    />
                    <div className="w-full py-1.5"></div> */}
                    <ColorPicker
                      loading={loading}
                      name="primary_colour"
                      label="Primary colour"
                      value={formData?.primary_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="secondary_colour"
                      label="Secondary colour"
                      value={formData?.secondary_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="button_colour"
                      label="Button colour"
                      value={formData?.button_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="button_text_colour"
                      label="Button text colour"
                      value={formData?.button_text_colour}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                    <div className="w-full py-1.5"></div>
                    <ColorPicker
                      loading={loading}
                      name="bg_color"
                      label="Background colour"
                      value={formData?.bg_color}
                      onChange={(e) => {
                        handleFieldOnChange(e);
                        syncView();
                      }}
                    />
                  </Col>
                  <Col width={8}>
                    <div
                      className={`
                      bg-[${formData?.primary_colour}] 
                      flex items-center justify-between relative 
                      w-full aspect-[7/1] 
                      py-2 px-3 select-none
                      text-center 
                      border border-[#dfe2e8] 
                      border-b-[6px] border-b-[${formData?.button_colour}] 
                    `}
                    >
                      <img
                        src={
                          formData?.logo_src && formData?.logo_src.length > 0
                            ? formData.logo_src
                            : ""
                        }
                        style={{
                          minWidth: "120px",
                          minHeight: "20px",
                          maxHeight: "44px",
                          cursor: "pointer",
                          padding: "0px",
                        }}
                        alt="Logo"
                        onClick={handleLogoSelectModal}
                        className={`hover:outline outline-1 outline-[${formData?.secondary_colour}]`}
                      />
                      <p
                        className={`text-xs pr-2 text-right font-light text-[${formData?.secondary_colour}]`}
                      >
                        Primary header with secondary text.
                      </p>
                    </div>
                    <div
                      className={`
                      bg-[${formData?.bg_color}] 
                      flex justify-start relative 
                      w-full aspect-[2/1] 
                      text-center 
                      relative select-none
                    `}
                    >
                      <img
                        src={
                          formData?.login_bg_src &&
                          formData?.login_bg_src.length > 0
                            ? formData.login_bg_src
                            : ""
                        }
                        className={` object-cover w-full cursor-pointer hover:outline outline-1 outline-[${formData?.secondary_colour}]`}
                        onClick={handleLoginBGSelectModal}
                        alt="Background"
                      />
                    </div>
                    <div className="w-full h-fit bg-white transition-all ease-in-out flex select-none">
                      <div
                        className={`w-full justify-between flex-row flex h-full bg-[${formData?.bg_color}]`}
                      >
                        <div className="flex-1 text-white text-xs font-light text-center self-center px-5 py-6">
                          White text should be easy to read on this background
                          colour
                        </div>
                        <button
                          type="button"
                          className={`justify-center py-1 px-4 border border-transparent text-xs leading-5 
                                      text-[${formData?.button_text_colour}] bg-[${formData?.button_colour}] pointer-events-none`}
                        >
                          Button
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {hasAccess(Permissions.Mutation.UpdateClientPlatform) && (
              <SaveButton
                onClick={handleSave}
                disabled={!isDraft || formHasErrors()}
                loading={isUpdating}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>
      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
      <ImageGalleryModal {...modalState} />
    </PageContainer>
  );
};

export default LandlordPlatform;
