import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Permissions from "../configs/permissions";
import usePermissions from "../hooks/permissions";
import { updateMessagingConfigMutation } from "./../graphql/mutations/systemMutations";
import { messagingConfig } from "./../graphql/queries/systemQueries";
import useBaseFieldSetters from "./../hooks/baseFieldSetters";
import useFormErrors from "./../hooks/formErrors";
import useHyperState from "./../hooks/hyperState";
import useNotification from "./../hooks/notifications";
import { FormControls, SaveButton } from "./../mood-ui/Controls";
import { NumberField, ToggleCheckboxField } from "./../mood-ui/Fields";
import {
  BlockContainer,
  Col,
  FormContainer,
  FormData,
  PageContainer,
  Row,
} from "./../mood-ui/Layout";
import {
  ErrorNotification,
  SuccessNotification,
} from "./../mood-ui/Notifications";
import PageSpinner from "./../mood-ui/PageSpinner";
import { Heading } from "./../mood-ui/Typography";

const CONFIG = gql(messagingConfig);
const UPDATE_CONFIG = gql(updateMessagingConfigMutation);

const MessaginConfig = () => {
  const { id: client_id } = useParams();
  const account_id = client_id ? parseInt(client_id) : 0;
  const { protectPage, hasAccess } = usePermissions();

  protectPage(Permissions.Mutation.UpdateClientPaymentSettings);

  const {
    setFieldError,
    formHasErrors,
    fieldError,
    resolveMutationErrors,
    clearError,
  } = useFormErrors();

  const {
    notify: displayNotification,
    notificationState,
    handleClose,
  } = useNotification();

  const {
    notify: displayErrorNotification,
    notificationState: errorNotificationState,
    handleClose: handleCloseError,
  } = useNotification();

  const {
    state: formData,
    setState: setFormData,
    stateMethods,
  } = useHyperState("formData", {
    account_id: account_id,
    send_activation_email_1: true,
    send_activation_email_2: true,
    send_activation_email_3: true,
    password_reset_enabled: true,
    send_order_confirmation: true,
  });

  const {
    setFieldValue: handleFieldOnChangeProxied,
    toggleCheckbox: handleToggleCheckbox,
  } = useBaseFieldSetters(formData, stateMethods, clearError);

  const handleFieldOnChange = (e) => {
    clearError(e.target.name);
    if (e.target?.checkValidity && !e.target?.checkValidity()) {
      setFieldError(
        e.target.name,
        e.target.title || e.target.validationMessage || "Input has an error"
      );
      setDraft(false);
    } else if (formHasErrors()) {
      setDraft(false);
    } else {
      setDraft(true);
    }
    handleFieldOnChangeProxied(e);
  };

  const { error, loading } = useQuery(CONFIG, {
    variables: {
      account_id,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (result) => {
      if (!result || !result.messagingConfig) return false;

      setFormData({
        ...result.messagingConfig,
      });
    },
  });

  const [update, { loading: isUpdating }] = useMutation(UPDATE_CONFIG, {
    variables: { ...formData },
    onCompleted: (result) => {
      displayNotification({
        heading: "Update successful",
        message: "Messaging Config was successfully updated",
        secondsToDisplay: 2,
      });
      setDraft(false);
    },
    onError: (e) => {
      resolveMutationErrors(e);
      displayErrorNotification({
        heading: "Update failed",
        message: "Failed to update messaging config",
        secondsToDisplay: 2,
      });
    },
  });

  const handleSave = () => {
    const data = {
      ...formData,
    };
    update({ variables: data });
  };

  const [_, setDraft] = useState(false);

  if (error) return <div>Error...</div>;
  if (!formData) return <PageSpinner />;

  return (
    <PageContainer>
      <BlockContainer raised>
        <Row tweaks="border-b border-[#dfe2e8] pb-8 mt-0 mx-5">
          <Col width={12}>
            <Heading
              inset={true}
              text="Update Messaging Provider Configuration"
              description=""
            />
          </Col>
        </Row>

        <FormContainer>
          <FormData>
            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Activation Emails"
                      description=""
                    />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      Setup which emails to send when a user generates an event
                      {formData.account_id > 0
                        ? " this client"
                        : " Vaboo App Globally"}
                      .
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  {account_id > 0 && (
                    <React.Fragment>
                      <Col width={4} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="use_global_settings"
                          name="use_global_settings"
                          label="Use Global Settings"
                          isChecked={formData?.use_global_settings}
                          onChange={(e) => {
                            setDraft(true);
                            handleToggleCheckbox(e);
                          }}
                          error={fieldError("use_global_settings")}
                          description="If checked, the global settings will be used for this client."
                        />
                      </Col>
                      <Col width={8}></Col>
                    </React.Fragment>
                  )}
                  {!formData.use_global_settings && (
                    <React.Fragment>
                      <Col width={6} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="send_activation_email_1"
                          name="send_activation_email_1"
                          label="Send Activation Email"
                          description="Send initial activation email when account is created"
                          isChecked={formData?.send_activation_email_1}
                          error={fieldError("send_activation_email_1")}
                          onChange={(e) => {
                            setDraft((d) => true);
                            handleToggleCheckbox(e);
                          }}
                        />
                      </Col>
                      <Col width={6} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="send_activation_email_2"
                          name="send_activation_email_2"
                          label="Send Activation Email. Number Two"
                          description="Send second activation email if the first one was ignored during 30 days"
                          isChecked={formData?.send_activation_email_2}
                          error={fieldError("send_activation_email_2")}
                          onChange={(e) => {
                            setDraft((d) => true);
                            handleToggleCheckbox(e);
                          }}
                        />
                      </Col>
                      <Col width={6} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="send_activation_email_3"
                          name="send_activation_email_3"
                          label="Send Activation Email. Number Three"
                          description="Send third activation email if previous emails were ignored"
                          isChecked={formData?.send_activation_email_3}
                          error={fieldError("send_activation_email_3")}
                          onChange={(e) => {
                            setDraft((d) => true);
                            handleToggleCheckbox(e);
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Password Reset Emails"
                      description=""
                    />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      Enable/disable password reset emails
                      {formData.account_id > 0
                        ? " this client"
                        : " Vaboo App Globally"}
                      .
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  {!formData.use_global_settings && (
                    <React.Fragment>
                      <Col width={6} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="password_reset_enabled"
                          name="password_reset_enabled"
                          label="Enable password reset emails"
                          isChecked={formData?.password_reset_enabled}
                          error={fieldError("password_reset_enabled")}
                          onChange={(e) => {
                            setDraft((d) => true);
                            handleToggleCheckbox(e);
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </Col>
            </Row>

            <Row tweaks="border-b border-[#dfe2e8] py-7 mx-5">
              <Col width={3}>
                <Row>
                  <Col width={12}>
                    <Heading
                      size="md"
                      text="Order Confirmation Emails"
                      description=""
                    />
                    <p
                      className={
                        "mt-1 text-sm leading-5 text-gray-400 select-none"
                      }
                    >
                      Enable/disable order confirmation emails. Those emails
                      will be sent to the user when a reward card or a cinema
                      ticket is purchased.
                      {formData.account_id > 0
                        ? " this client"
                        : " Vaboo App Globally"}
                      .
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col
                width={1}
                extraStyles="border-r border-[#dfe2e8] h-full justify-self-center w-full"
              ></Col>
              <Col width={8}>
                <Row>
                  {!formData.use_global_settings && (
                    <React.Fragment>
                      <Col width={6} extraStyles="">
                        <ToggleCheckboxField
                          loading={loading}
                          id="send_order_confirmation"
                          name="send_order_confirmation"
                          label="Send order confirmation emails"
                          isChecked={formData?.send_order_confirmation}
                          error={fieldError("send_order_confirmation")}
                          onChange={(e) => {
                            setDraft((d) => true);
                            handleToggleCheckbox(e);
                          }}
                        />
                      </Col>
                    </React.Fragment>
                  )}
                </Row>
              </Col>
            </Row>
          </FormData>
          <FormControls>
            {hasAccess(Permissions.Mutation.UpdateClientPaymentSettings) && (
              <SaveButton
                onClick={handleSave}
                loading={isUpdating}
                disabled={formHasErrors()}
              />
            )}
          </FormControls>
        </FormContainer>
      </BlockContainer>

      <SuccessNotification {...notificationState} onClose={handleClose} />
      <ErrorNotification
        {...errorNotificationState}
        onClose={handleCloseError}
      />
    </PageContainer>
  );
};

export default MessaginConfig;
